export const defaultAdvancedSearch = {
  boldKeywords: true,
  contentTypes: [],
  contributorIds: [],
  dateOffset: 1,
  edition: '',
  frontPage: false,
  hasFile: false,
  inByline: true,
  inHeadline: true,
  inStory: true,
  inProgram: true,
  mediaTypeIds: [],
  page: '',
  publishedEndOn: '',
  publishedStartOn: '',
  startDate: undefined,
  endDate: undefined,
  search: '',
  section: '',
  sentiment: [],
  seriesIds: [],
  sourceIds: [],
  tags: [],
  topStory: false,
  searchUnpublished: false,
  size: 500,
};
